import * as React from 'react';
import { GridColumns } from '@mui/x-data-grid';
import { Checkbox, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch, useSelector } from 'react-redux';
import { PrimarySelect, PrimaryTable } from 'components';
import { IOption } from 'interfaces';
import { Role } from 'interfaces/user-roles';
import { getOptionsFromEnum } from 'utils/data-utils';
import { AuthorityLevels, Permissions } from 'config';
import { selectUser } from 'base/auth/selectors';
import { AdminDocument } from 'pages/dashboard/components/ent-account/components/edit-account/components/admins/interface';
import { onboardEntAccActions } from '../../../../slice';

interface EntAdminProps {
  entAdmins: AdminDocument[];
  loading: boolean;
  onDelete: (row: AdminDocument) => void;
}

export default function EntAdminTable({ entAdmins, loading, onDelete }: EntAdminProps) {
  const currentAdmin = useSelector(selectUser);
  type Row = typeof entAdmins[number];

  const authorityLevelOptions: IOption[] = getOptionsFromEnum(AuthorityLevels);

  const dispatch = useDispatch();

  const columns = React.useMemo<GridColumns<Row>>(
    () => [
      {
        field: 'firstName',
        headerName: 'Name',
        flex: 0.4,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => (
          <div>
            {params.row.firstName}
            {' '}
            {params.row.lastName}
          </div>
        ),
      },
      {
        field: 'email',
        headerName: 'Email address',
        flex: 0.5,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'attributes',
        headerName: 'Authority Level',
        flex: 0.4,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => (
          <PrimarySelect
            label=""
            value={params.row.attributes.authorityLevel}
            onChange={(e: { target: { value: string; }; }) => {
              dispatch(onboardEntAccActions.changeAdminAuthorityLevel({
                id: params.row.id,
                authorityLevel: e.target.value,
                permissions: params.row.roles[0].permissions,
              }));
            }}
            options={authorityLevelOptions}
            placeholder="Select Authority level"
          />
        ),
      },
      {
        field: 'authenticateComplaints',
        headerName: 'Authenticate Complaints',
        flex: 0.3,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          const role = params.row.roles.find((r) => r.role === Role.EntAccountAdmin);

          return (
            <Checkbox
              disabled={params.row.attributes.authorityLevel === AuthorityLevels.GROUP_ADMIN}
              checked={role?.permissions.includes(Permissions.authenticateComplaints)}
              onChange={(e: { target: { checked: boolean; }; }) => {
                const newPermissions = [...role ? role.permissions : []];

                if (e.target.checked) {
                  newPermissions.push(Permissions.authenticateComplaints);
                } else {
                  const permissionIndex = newPermissions.indexOf(
                    Permissions.authenticateComplaints,
                  );
                  if (permissionIndex > -1) {
                    newPermissions.splice(permissionIndex, 1);
                  }
                }

                dispatch(onboardEntAccActions.changeAdminAuthorityLevel({
                  id: params.row.id,
                  authorityLevel: params.row.attributes.authorityLevel,
                  permissions: newPermissions,
                }));
              }}
            />
          );
        },
      },
      {
        field: 'articles',
        headerName: 'Articles',
        flex: 0.3,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          const role = params.row.roles.find((r) => r.role === Role.EntAccountAdmin);

          return (
            <Checkbox
              disabled={params.row.attributes.authorityLevel === AuthorityLevels.GROUP_ADMIN}
              checked={role?.permissions.includes(Permissions.moderateArticles)}
              onChange={(e: { target: { checked: boolean; }; }) => {
                const newPermissions = [...role ? role.permissions : []];

                if (e.target.checked) {
                  newPermissions.push(Permissions.moderateArticles);
                } else {
                  const permissionIndex = newPermissions.indexOf(Permissions.moderateArticles);
                  if (permissionIndex > -1) {
                    newPermissions.splice(permissionIndex, 1);
                  }
                }

                dispatch(onboardEntAccActions.changeAdminAuthorityLevel({
                  id: params.row.id,
                  authorityLevel: params.row.attributes.authorityLevel,
                  permissions: newPermissions,
                }));
              }}
            />
          );
        },
      },
      {
        field: 'accessClasses',
        headerName: 'Classes',
        flex: 0.3,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          const role = params.row.roles.find((r) => r.role === Role.EntAccountAdmin);

          return (
            <Checkbox
              disabled={params.row.attributes.authorityLevel === AuthorityLevels.GROUP_ADMIN}
              checked={role?.permissions.includes(Permissions.accessClasses)}
              onChange={(e: { target: { checked: boolean; }; }) => {
                const newPermissions = [...role ? role.permissions : []];

                if (e.target.checked) {
                  newPermissions.push(Permissions.accessClasses);
                } else {
                  const permissionIndex = newPermissions.indexOf(Permissions.accessClasses);
                  if (permissionIndex > -1) {
                    newPermissions.splice(permissionIndex, 1);
                  }
                }

                dispatch(onboardEntAccActions.changeAdminAuthorityLevel({
                  id: params.row.id,
                  authorityLevel: params.row.attributes.authorityLevel,
                  permissions: newPermissions,
                }));
              }}
            />
          );
        },
      },
      {
        field: 'actions',
        headerName: '',
        flex: 0.2,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params) => (
          <IconButton
            onClick={() => onDelete(params.row)}
            disabled={(params.row.email === currentAdmin.email)}
          >
            <ClearIcon sx={{ color: (params.row.email === currentAdmin.email) ? 'grey' : 'red' }} />
          </IconButton>
        ),
      },
    ],
    [],
  );

  return (
    <PrimaryTable disableSelectionOnClick rows={entAdmins} columns={columns} loading={loading} />
  );
}
