/* eslint-disable no-underscore-dangle */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeedbackStatus } from 'config';
import {
  IFeedBack, IFeedbackFilterPayload, IFeedbackResponse, ViewFeedbackState,
} from './interface';

export const initialState: ViewFeedbackState = {
  feedbacksLoading: false,
  loading: false,
  feedbacks: { result: [], total: 0 },
  totalPending: 0,
  totalResolved: 0,
  totalPickedUp: 0,

};

export const viewFeedbackSlice = createSlice({
  name: 'feature/ent-acc/view-feedback',
  initialState,
  reducers: {
    viewFeedback(state: ViewFeedbackState, _action:PayloadAction<IFeedbackFilterPayload>) {
      state.feedbacksLoading = true;
    },
    viewFeedbackSucceeded(
      state: ViewFeedbackState,
      action:PayloadAction<IFeedbackResponse>,
    ) {
      if (action.payload.result.length > 0) {
        state.totalPending = action.payload.statusCounts.find((status) => status.status
      === FeedbackStatus.PENDING)?.count || 0;
        state.totalResolved = action.payload.statusCounts.find((status) => status.status
      === FeedbackStatus.RESOLVED)?.count || 0;
        state.totalPickedUp = action.payload.statusCounts.find((status) => status.status
      === FeedbackStatus.PICKED_UP)?.count || 0;
      }
      state.feedbacks.result = action.payload.result.map((feedback) => ({
        ...feedback,
        modalState: false,
      }));
      state.feedbacks.total = action.payload.total;
      state.feedbacksLoading = false;
    },
    viewFeedbackFailed(state: ViewFeedbackState) {
      state.feedbacksLoading = false;
    },
    editFeedback(state: ViewFeedbackState, _action:PayloadAction<IFeedBack>) {
      state.loading = true;
    },
    editFeedbackSucceeded(state: ViewFeedbackState, action:PayloadAction<IFeedBack>) {
      const temp = state.feedbacks.result.map((feedback) => {
        if (feedback._id === action.payload._id) {
          return {
            ...feedback,
            ...action.payload,
            modalState: false,
          };
        }
        return feedback;
      });

      state.totalPending = 0;
      state.totalResolved = 0;
      state.totalPickedUp = 0;
      state.feedbacks.result = temp.map((feedback) => {
        if (feedback.status
          === FeedbackStatus.RESOLVED) { state.totalResolved += 1; } else if (feedback.status
            === FeedbackStatus.PICKED_UP) { state.totalPickedUp += 1; } else {
          state.totalPending += 1;
        }
        return {
          ...feedback,
          modalState: false,
        };
      });
      state.feedbacks.result = temp;
      state.loading = false;
    },
    editFeedbackFailed(state: ViewFeedbackState) {
      state.loading = false;
    },
    openModal(state:ViewFeedbackState, action:PayloadAction<string>) {
      const temp = [...state.feedbacks.result];
      temp.forEach((feedback) => {
        if (feedback._id === action.payload) {
          feedback.modalState = true;
        }
      });
      state.feedbacks.result = [...temp];
    },
    closeModal(state:ViewFeedbackState, action:PayloadAction<string>) {
      const temp = [...state.feedbacks.result];
      temp.forEach((feedback) => {
        if (feedback._id === action.payload) {
          feedback.modalState = false;
        }
      });
      state.feedbacks.result = [...temp];
    },

  },
});

export const { actions: viewFeedbackActions } = viewFeedbackSlice;
