import * as React from 'react';
import {
  Controller, SubmitHandler, useFieldArray, useFormContext,
} from 'react-hook-form';
import {
  Grid, Typography,
} from '@mui/material';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { SessionAudienceTypes } from 'config';
import {
  ErrorMessage,
  PrimaryDatePicker,
  PrimaryLoadingButton,
} from 'components';
import { CourseOnlySessionStatus, ILiveSessionAvailabilityForm } from '../../../../interface';
import AvailableTime from '../available-time';
import { IAvailableTime } from '../../../../../../interface';
import { selectCourseOnlySessionMeta, selectLoader, selectSessionInformation } from '../../../../selector';

interface SessionAvailabilityFormProps {
  onSubmit: SubmitHandler<ILiveSessionAvailabilityForm>;
}

const SessionAvailabilityForm = ({
  onSubmit,
}: SessionAvailabilityFormProps) => {
  const {
    control,
    handleSubmit, setValue,
    formState: { errors }, watch,
  } = useFormContext<ILiveSessionAvailabilityForm>();

  const loading = useSelector(selectLoader);
  const { audienceType } = useSelector(selectSessionInformation);
  const courseOnlySessionStatus = useSelector(selectCourseOnlySessionMeta).status;
  const { fields } = useFieldArray({ name: 'availableTimes', control });

  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  const watchSessionMetaStartDate = watch('sessionMeta.startDate');
  const watchSessionMetaEndDate = watch('sessionMeta.endDate');

  const handleChange = (availableTime: IAvailableTime) => {
    const index = days.indexOf(availableTime.day);
    setValue(`availableTimes.${index}`, availableTime);
  };

  React.useEffect(() => {
    if (moment(`${watchSessionMetaStartDate}`, 'YYYY-MM-DD').isSameOrAfter(moment(`${watchSessionMetaEndDate}`, 'YYYY-MM-DD'))) {
      const newEndDateTime = moment(`${watchSessionMetaStartDate}`, 'YYYY-MM-DD').add(14, 'day');
      setValue('sessionMeta.endDate', newEndDateTime.format('YYYY-MM-DD'));
    }
  }, [watchSessionMetaStartDate, watchSessionMetaEndDate, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h5" sx={{ mb: 1 }} className="form-item">Available dates</Typography>
      <Typography variant="body2" sx={{ mb: 3, color: 'grey.A700' }}>Set date and times that you will make yourself available for a booking.</Typography>

      <Grid>
        <Grid item>
          <div className="form-item">
            <Controller
              control={control}
              name="sessionMeta.startDate"
              render={({ field: { value, onChange } }) => (
                <PrimaryDatePicker
                  placeholder="select date"
                  label="Start date"
                  value={moment(value, 'YYYY-MM-DD')}
                  onChange={(newValue: any) => {
                    onChange(moment(newValue).format('YYYY-MM-DD'));
                  }}
                />
              )}
            />
          </div>
          <ErrorMessage>{errors?.sessionMeta?.startDate}</ErrorMessage>
        </Grid>
        <Grid item>
          <div className="form-item">
            <Controller
              control={control}
              name="sessionMeta.endDate"
              render={({ field: { value, onChange } }) => (
                <PrimaryDatePicker
                  placeholder="select date"
                  label="End date"
                  minDate={moment(watchSessionMetaStartDate, 'YYYY-MM-DD').add(2, 'days')}
                  maxDate={moment(watchSessionMetaStartDate, 'YYYY-MM-DD').add(6, 'month')}
                  value={moment(value, 'YYYY-MM-DD')}
                  onChange={(newValue: any) => {
                    onChange(moment(newValue).format('YYYY-MM-DD'));
                  }}

                />
              )}
            />
          </div>
          <ErrorMessage>{errors?.sessionMeta?.endDate}</ErrorMessage>

        </Grid>
      </Grid>

      {fields.map((item, i) => (
        <div className="form-item" key={item.day}>
          <Grid container justifyItems="center">
            <Grid item xs={1}>
              {item.day}
            </Grid>
            <Grid item xs>
              <AvailableTime
                handleChange={handleChange}
                initialValue={item}
                errors={errors?.availableTimes ? errors?.availableTimes[i] : {}}
              />
            </Grid>
          </Grid>
        </div>
      ))}
      <ErrorMessage style={{ marginBottom: '20px', display: 'block' }}>{errors?.availableTimes?.message}</ErrorMessage>
      <Grid container spacing={3}>
        <Grid item xs>
          <PrimaryLoadingButton loading={loading} type="submit">
            {audienceType === SessionAudienceTypes.courseOnly ? `${courseOnlySessionStatus !== CourseOnlySessionStatus.editing ? 'Add to course' : 'Update session'}` : '  Save & continue'}
          </PrimaryLoadingButton>
        </Grid>

      </Grid>
    </form>
  );
};

export default SessionAvailabilityForm;
