/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import {
  CircularProgress, Divider, Grid, Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  GrowthDisplay, PrimarySelect, PrimarySnackbar, TopBar, WhiteBgCard,
} from 'components';
import { IOption } from 'interfaces';
import { getOptionsFromEnum } from 'utils/data-utils';

import './index.scss';
import { selectSnack } from 'base/core/selector';
import { coreSliceActions } from 'base/core/slice';
import useAuthorization from 'base/auth/hooks/use-authorization';
import { Role } from 'interfaces/user-roles';
import { selectEntAccId } from 'base/auth/selectors';
import FilterOptions from 'config/overview';
import socket from 'utils/socket';
import TopPerformingSessions from './components/top-performing-sessions';
import UserChart from './components/user-chart';
import { overviewActions } from './slice';
import {
  selectEntAccounts,
  selectEntAccountsLoader, selectEntId, selectFeedback,
  selectUserSignInAnalytics, selectStateLoader,
  selectTopPerformanceSessions, selectTopSessionLoader, selectUniqueUsers,
} from './selector';
import Activity from './components/activity';
import { AnalyticsTypes, IWebsocketResponse } from './interface';

const DashboardView = () => {
  const dispatch = useDispatch();
  const entAccounts = useSelector(selectEntAccounts);
  const entAccountsLoading = useSelector(selectEntAccountsLoader);
  const stateLoader = useSelector(selectStateLoader);
  const topPerformingSessionLoader = useSelector(selectTopSessionLoader);
  const topPerformingSessions = useSelector(selectTopPerformanceSessions);
  const feedback = useSelector(selectFeedback);
  const filterOptions = getOptionsFromEnum(FilterOptions);
  const userSignInAnalytics = useSelector(selectUserSignInAnalytics);
  const uniqueUsers = useSelector(selectUniqueUsers);
  const adminEntId = useSelector(selectEntAccId);
  const [entOptions, setEntOptions] = React.useState<IOption[]>(
    entAccounts.length > 0 ? entAccounts?.map((entAcc) => (
      { id: entAcc._id, value: entAcc.name, text: entAcc.name }))
      : [{ id: '', text: '', value: '' }],
  );
  const [filterValue, setFilterValue] = React.useState(FilterOptions.date);
  const [isConnected, setIsConnected] = React.useState(socket.connected);

  const { isAuthorized } = useAuthorization();
  const entId = useSelector(selectEntId);
  const { open, message, success } = useSelector(selectSnack);

  const handleClose = () => {
    dispatch(coreSliceActions.closeSnackbar());
  };

  const sendMessage = () => {
    const endDateRaw = new Date().getTime() + (1 * 24 * 60 * 60 * 1000);
    const startDateRaw = new Date(endDateRaw - (31 * 24 * 60 * 60 * 1000));
    const startDate = moment(startDateRaw).format('YYYY-MM-DD');
    const endDate = moment(endDateRaw).format('YYYY-MM-DD');
    dispatch(overviewActions.getUserSignInAnalytics());
    dispatch(overviewActions.getUniqueUsers());
    dispatch(overviewActions.getTopPerformingSessions());
    dispatch(overviewActions.getActivities());

    socket.emit('bulk', {
      type: AnalyticsTypes.USERS,
      startDate,
      endDate,
      entId,
    });
    socket.emit('bulk', {
      type: AnalyticsTypes.UNIQUE_USERS,
      startDate,
      endDate,
      entId,
    });
    socket.emit('bulk', {
      type: AnalyticsTypes.ACTIVITY,
      startDate,
      endDate,
      entId,
    });
    socket.emit('bulk', {
      type: AnalyticsTypes.TOP_PERFORMING_SESSIONS,
      startDate,
      endDate,
      entId,
    });
    socket.emit('bulk', {
      type: 'FEEDBACK',
      startDate,
      endDate,
      entId,
    });
  };

  const connect = () => {
    if (!isConnected) {
      socket.connect();
    } else {
      socket.disconnect();
      socket.connect();
    }
  };

  useEffect(() => {
    if (isAuthorized([Role.SuperAdmin])) {
      dispatch(overviewActions.getEntAccounts());
    }
  }, []);

  useEffect(() => {
    if (entAccounts.length > 0) {
      setEntOptions(entAccounts?.map((entAcc) => (
        { id: entAcc._id, value: entAcc._id, text: entAcc.name })));
      dispatch(overviewActions.setEntId(entAccounts[0]._id));
    }
  }, [entAccounts]);

  useEffect(() => {
    if (entId) {
      connect();
    }
  }, [entId]);

  useEffect(() => {
    if (!isAuthorized([Role.SuperAdmin])) {
      dispatch(overviewActions.setEntId(adminEntId));
    }
  }, []);

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
      socket.emit('join', { entId });
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    function onResponseEvent(value:IWebsocketResponse) {
      if (value.connected) {
        sendMessage();
      }
      if (value.type === AnalyticsTypes.TOP_PERFORMING_SESSIONS) {
        if (value.response.success) {
          dispatch(overviewActions.getTopPerformingSessionsSucceeded(
            { topPerformingSessions: value.response.sessions },
          ));
        } else {
          dispatch(overviewActions.getTopPerformingSessionsFailed());
        }
      }
      if (value.type === AnalyticsTypes.ACTIVITY) {
        if (value.response.success) {
          dispatch(overviewActions.getActivitiesSucceeded(
            { activities: value.response.activities },
          ));
        } else {
          dispatch(overviewActions.getActivitiesFailed());
        }
      }
      if (value.type === AnalyticsTypes.USERS) {
        if (value.response.success) {
          dispatch(overviewActions.getUserSignInAnalyticsSucceeded(
            { userSignInAnalytics: value.response },
          ));
        } else {
          dispatch(overviewActions.getUserSignInAnalyticsFailed());
        }
      }
      if (value.type === AnalyticsTypes.UNIQUE_USERS) {
        if (value.response.success) {
          dispatch(overviewActions.getUniqueUsersSucceeded({ uniqueUsers: value.response }));
        } else {
          dispatch(overviewActions.getUniqueUsersFailed());
        }
      }
      if (value.type === AnalyticsTypes.FEEDBACK) {
        if (value.response.success) {
          dispatch(overviewActions.getFeedbackSucceeded({ feedback: value.response }));
        } else {
          dispatch(overviewActions.getFeedbackFailed());
        }
      }
    }
    function onErrorEvent(value:any) {
      console.log(value);
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('response', onResponseEvent);
    socket.on('error', onErrorEvent);

    return () => {
      if (isConnected) {
        socket.disconnect();
      }
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('response', onResponseEvent);
      socket.on('error', onErrorEvent);
    };
  }, [entId]);

  return (
    <div>
      <TopBar />
      <div style={{ paddingLeft: '1rem' }}>
        <PrimarySnackbar open={open} message={message} severity={success ? 'success' : 'error'} handleClose={handleClose} />
        <Grid container sx={{ height: '100%' }} justifyContent="space-between">
          <Grid
            item
            xs={9}
            sx={{
              padding: ' 0 2rem', height: 'calc(100vh - 64px)', overflow: 'hidden', paddingBottom: '10rem',
            }}
          >
            <Grid container alignItems="center" justifyContent="space-between" sx={{ margin: '1rem 0' }}>
              <Grid item>
                <h1>Overview</h1>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
                  {isAuthorized([Role.SuperAdmin]) ? (
                    <Grid item className="overview-selector">
                      <PrimarySelect
                        loading={entAccountsLoading}
                        options={entOptions}
                        value={entId}
                        onChange={(event: { target: { value: string; }; }) => {
                          dispatch(overviewActions.setEntId(event.target.value));
                        }}
                      />
                    </Grid>
                  ) : ('')}
                  <Grid item className="overview-selector">
                    <PrimarySelect
                      options={filterOptions}
                      value={filterValue}
                      onChange={(event: { target: { value: FilterOptions; }; }) => {
                        setFilterValue(event.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
            <Divider style={{ width: '100%', marginBottom: '1rem' }} />
            {stateLoader ? <Grid container justifyContent="center" alignItems="center" sx={{ margin: 'auto', paddingBottom: '5rem', height: 'calc(100vh-114px)' }}><CircularProgress /></Grid> : (
              <div style={{ overflowY: 'auto', height: '100%' }}>
                <Grid container alignItems="center" justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h2">
                      Total repeat users
                    </Typography>
                    <div className="inline-wrapper">
                      <Typography variant="body1" sx={{ fontSize: '2rem' }}>
                        {userSignInAnalytics.totalRepeated}
                      </Typography>
                      <GrowthDisplay
                        up={userSignInAnalytics.totalRepeated
                                  >= userSignInAnalytics.totalPastRepeated}
                        value={Math.abs(userSignInAnalytics.totalRepeated
                                  - userSignInAnalytics.totalPastRepeated)}
                      />
                    </div>

                  </Grid>
                  <Grid item xs={5}>
                    <Grid container justifyContent="space-evenly">
                      <Grid item>
                        <div className="inline-wrapper">
                          <div className="bar user-bar" />
                          <div>
                            <div className="inline-wrapper">
                              <Typography variant="body1" sx={{ fontSize: '1.25rem' }}>
                                {userSignInAnalytics.users?.total}
                              </Typography>
                              <GrowthDisplay
                                up={userSignInAnalytics.users?.total
                                  >= userSignInAnalytics.users?.pastTotal}
                                value={Math.abs(userSignInAnalytics.users?.total
                                  - userSignInAnalytics.users?.pastTotal)}
                              />
                            </div>
                            <Typography variant="h4">Users</Typography>
                          </div>

                        </div>
                      </Grid>
                      <Grid item>
                        <div className="inline-wrapper">
                          <div className="bar expert-bar" />
                          <div>
                            <div className="inline-wrapper">
                              <Typography variant="body1" sx={{ fontSize: '1.25rem' }}>
                                {userSignInAnalytics.experts?.total}
                              </Typography>
                              <GrowthDisplay
                                up={userSignInAnalytics.experts?.total
                                  >= userSignInAnalytics.experts?.pastTotal}
                                value={Math.abs(userSignInAnalytics.experts?.total
                                  - userSignInAnalytics.experts?.pastTotal)}
                              />
                            </div>
                            <Typography variant="h4">Experts</Typography>
                          </div>

                        </div>
                      </Grid>
                      <Grid item>
                        <div className="inline-wrapper">
                          <div className="bar admin-bar" />
                          <div>
                            <div className="inline-wrapper">
                              <Typography variant="body1" sx={{ fontSize: '1.25rem' }}>
                                {userSignInAnalytics.admins?.total}
                              </Typography>
                              <GrowthDisplay
                                up={userSignInAnalytics.admins?.total
                                  >= userSignInAnalytics.admins?.pastTotal}
                                value={Math.abs(userSignInAnalytics.admins?.total
                                  - userSignInAnalytics.admins?.pastTotal)}
                              />
                            </div>
                            <Typography variant="h4">Admins</Typography>
                          </div>

                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <UserChart />
                <Grid container justifyContent="space-between">
                  <Grid item xs={2}>
                    <WhiteBgCard>
                      <Typography variant="h3">
                        Unique users
                      </Typography>
                      <div className="inline-wrapper">
                        <Typography variant="body1" sx={{ fontSize: '2rem' }}>
                          {uniqueUsers.total}
                        </Typography>
                        <GrowthDisplay
                          up={uniqueUsers?.total
                                  >= uniqueUsers?.pastTotal}
                          value={Math.abs(uniqueUsers?.total
                                  - uniqueUsers?.pastTotal) || 0}
                        />
                      </div>

                    </WhiteBgCard>

                  </Grid>
                  <Grid item xs={2}>
                    <WhiteBgCard>
                      <Typography variant="h3">
                        Page views
                      </Typography>
                      <div className="inline-wrapper">
                        <Typography variant="body1" sx={{ fontSize: '2rem' }}>
                          0
                        </Typography>
                        <GrowthDisplay up value={0} />
                      </div>

                    </WhiteBgCard>
                  </Grid>
                  <Grid item xs={7}>
                    <WhiteBgCard>
                      <Grid container alignItems="center">
                        <Grid item xs={6}>

                          <Typography variant="h3">
                            Total feedbacks
                          </Typography>
                          <div className="inline-wrapper">
                            <Typography variant="body1" sx={{ fontSize: '2rem' }}>
                              {feedback.total}
                            </Typography>
                            <GrowthDisplay
                              up={feedback?.total
                                  >= feedback?.pastTotal}
                              value={Math.abs(feedback?.total
                                  - feedback?.pastTotal) || 0}
                            />
                          </div>

                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="h4">Resolved</Typography>
                          <Typography variant="body1" sx={{ fontSize: '1.25re' }}>{feedback.resolved}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="h4">Picked up</Typography>
                          <Typography variant="body1" sx={{ fontSize: '1.25re' }}>{feedback.pickedUp}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="h4">Pending</Typography>
                          <Typography variant="body1" sx={{ fontSize: '1.25re' }}>{feedback.pending}</Typography>
                        </Grid>
                      </Grid>
                    </WhiteBgCard>
                  </Grid>
                </Grid>
                <TopPerformingSessions
                  loading={topPerformingSessionLoader}
                  sessions={topPerformingSessions}
                />
              </div>
            )}

          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              backgroundColor: '#FAFAFA', width: '100%', height: 'calc(100vh - 64px)', overflow: 'hidden', paddingBottom: '5rem',
            }}
          >
            <Grid container alignItems="center" justifyContent="space-between" sx={{ margin: '1.25rem 0' }}>
              <Grid item>
                <Typography variant="h2" sx={{ marginLeft: '1rem' }}>Activities</Typography>
              </Grid>
            </Grid>

            <Divider style={{ width: '100%' }} />
            <Activity />
          </Grid>

        </Grid>

      </div>
    </div>

  );
};

export default DashboardView;
