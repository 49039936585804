/* eslint-disable no-nested-ternary */
import { Box, Typography } from '@mui/material';
import { GalleryIcon } from 'assets/icons';
import { VideoPlayer } from 'components/molecules';
import compressFile from 'assets/images/compress-file.png';
import { FileType } from 'utils/file-uploader';

interface ContentMediaViewProps {
  mediaType: string;
  fileName?: string | undefined;
  thumbnail?: string;
  url?: string;
  backgroundSize?: string;
}

const ContentMediaView = ({
  mediaType, fileName, url, thumbnail, backgroundSize = '80%',
}: ContentMediaViewProps) => (
  <div style={{ height: '100%' }}>
    {url === ''
      ? (

        <Box
          sx={{
            backgroundColor: '#F2F2F2',
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '5px',
          }}
        >
          <GalleryIcon fontSize="large" />
        </Box>

      )

      : (
        mediaType === FileType.IMAGE ? (
          <Box
            sx={{
              backgroundImage: `url(${url})`,
              backgroundColor: '#F2F2F2',
              backgroundSize,
              backgroundPosition: 'center',
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              backgroundRepeat: 'no-repeat',
              alignItems: 'center',
              borderRadius: '5px',
            }}
          />
        )
          : mediaType === FileType.VIDEO ? (
            <VideoPlayer url={url} thumbnail={thumbnail} />
          ) : (
            mediaType === FileType.COMPRESSED_FILE ? (
              <Box
                sx={{
                  backgroundColor: '#F2F2F2',
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <Box
                  component="img"
                  sx={{
                    width: '65px',
                    height: 'auto',
                    objectFit: 'cover',
                    margin: '20px',
                  }}
                  src={compressFile}

                />
                { fileName && fileName.length > 0 && (
                <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2, textAlign: 'center' }}>
                  {fileName}
                </Typography>
                )}
              </Box>
            ) : (
              <Box
                sx={{
                  backgroundColor: '#F2F2F2',
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '5px',
                }}
              >
                <GalleryIcon fontSize="large" />
              </Box>
            )
          ))}
  </div>

);

ContentMediaView.defaultProps = {
  fileName: '',
  url: '',
  thumbnail: '',
  backgroundSize: '80%',
};

export default ContentMediaView;
