/* eslint-disable no-nested-ternary */
import { Controller, SubmitHandler, useFormContext } from 'react-hook-form';

import {
  Grid, Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import React from 'react';
import {
  ErrorMessage,
  PrimaryLoadingButton,
  PrimaryNumericField,
} from 'components';
import { SessionAudienceTypes, SessionType } from 'config';
import { ISessionFeeForm } from '../../../../interface';
import { selectLoader, selectSessionInformation } from '../../../../selector';
import { selectGeneralInformation } from '../../../../../../../../selector';

interface SessionFeeFormProps {
  onSubmit: SubmitHandler<any>;
  editMode:boolean;
}

const SessionFeeForm = ({
  onSubmit, editMode,
}: SessionFeeFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors }, setValue,
  } = useFormContext<ISessionFeeForm>();
  const { sessionType, audienceType, isObserverAllowed } = useSelector(selectSessionInformation);
  const { currency } = useSelector(selectGeneralInformation);
  const loading = useSelector(selectLoader);
  React.useEffect(() => {
    setValue('currency', currency);
    setValue('subscriberCurrency', currency);
    setValue('observerCurrency', currency);
    setValue('subscribeObserverCurrency', currency);
  }, [currency]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h4" className="form-item">
        Session Fees
      </Typography>
      {audienceType === SessionAudienceTypes.subscription && (

      <Grid container justifyContent="space-between" marginBottom={sessionType === SessionType.liveClass ? 0 : 4} spacing={4} direction={sessionType === SessionType.liveClass ? 'row' : 'column'}>
        <Grid item xs>
          <div className="form-item currency">
            <Controller
              control={control}
              name="subscriberFee"
              render={({ field }) => (
                <PrimaryNumericField
                  testId="create-session-subscription-fee"
                  {...field}
                  label={sessionType === SessionType.liveClass ? 'Subscribed participant fee' : 'Subscriber fee'}
                  decimalAllowed
                  max={1000000}
                />
              )}
            />

            <ErrorMessage>{errors?.subscriberFee?.message}</ErrorMessage>
          </div>
        </Grid>
        <Grid item xs>
          <div className="form-item">
            <Controller
              control={control}
              name="subscriberDiscount"
              render={({ field }) => (
                <PrimaryNumericField
                  {...field}
                  label={sessionType === SessionType.liveClass ? 'Subscribed participant discounts (%)' : 'Subscriber discounts (%)'}
                  min={0}
                  max={99}
                />
              )}
            />
            <ErrorMessage>{errors?.subscriberDiscount?.message}</ErrorMessage>
          </div>
        </Grid>

      </Grid>
      )}
      <Grid container justifyContent="space-between" spacing={4} direction={sessionType === SessionType.liveClass ? 'row' : 'column'}>
        <Grid item xs>
          <div className="form-item currency">
            <Controller
              control={control}
              name="fee"
              render={({ field }) => (
                <PrimaryNumericField
                  {...field}
                  decimalAllowed
                  label={sessionType === SessionType.liveClass ? (audienceType === SessionAudienceTypes.subscription ? 'Non subscriber fee' : 'Participant fee per session') : (audienceType === SessionAudienceTypes.subscription ? 'Non subscriber fee' : 'Fee per session')}
                  max={1000000}
                />
              )}
            />

            <ErrorMessage>{errors?.fee?.message}</ErrorMessage>
          </div>
        </Grid>
        <Grid item xs>
          <div className="form-item">
            <Controller
              control={control}
              name="discount"
              render={({ field }) => (
                <PrimaryNumericField
                  {...field}
                  label={sessionType === SessionType.liveClass ? (audienceType === SessionAudienceTypes.subscription ? 'Non subscriber discounts (%)' : 'Participant discounts (%)') : (audienceType === SessionAudienceTypes.subscription ? 'Non subscriber discounts (%)' : 'Discounts (%)')}
                  min={0}
                  max={99}
                />
              )}
            />
            <ErrorMessage>{errors?.discount?.message}</ErrorMessage>
          </div>
        </Grid>

      </Grid>

      {' '}
      {(sessionType === SessionType.liveClass && isObserverAllowed) ? (
        <>
          {audienceType === SessionAudienceTypes.subscription && (

          <Grid container justifyContent="space-between" spacing={4} direction={sessionType === SessionType.liveClass ? 'row' : 'column'}>
            <Grid item xs>
              <div className="form-item currency">
                <Controller
                  control={control}
                  name="subscribeObserverFee"
                  render={({ field }) => (
                    <PrimaryNumericField
                      {...field}
                      decimalAllowed
                      label="Subscribed observer fee"
                      testId="create-session-subscribed-observer-fee"
                      max={1000000}
                    />
                  )}
                />

                <ErrorMessage>{errors?.subscribeObserverFee?.message}</ErrorMessage>
              </div>
            </Grid>
            <Grid item xs>
              <div className="form-item">
                <Controller
                  control={control}
                  name="subscribeObserverDiscount"
                  render={({ field }) => (
                    <PrimaryNumericField
                      {...field}
                      label="Subscribed observer discounts (%)"
                      testId="create-session-subscribed-observer-discount"
                      min={0}
                      max={99}
                    />
                  )}
                />
                <ErrorMessage>{errors?.subscribeObserverDiscount?.message}</ErrorMessage>
              </div>
            </Grid>

          </Grid>
          )}
          <Grid container justifyContent="space-between" spacing={4}>
            <Grid item xs>
              <div className="form-item currency">
                <Controller
                  control={control}
                  name="observerFee"
                  render={({ field }) => (
                    <PrimaryNumericField
                      {...field}
                      decimalAllowed
                      label={audienceType === SessionAudienceTypes.subscription ? 'Non-subscribed observer fee' : 'Observer fee per session'}
                      max={1000000}
                    />
                  )}
                />

                <ErrorMessage>{errors?.observerFee?.message}</ErrorMessage>
              </div>
            </Grid>
            <Grid item xs>
              <div className="form-item">
                <Controller
                  control={control}
                  name="observerDiscount"
                  render={({ field }) => (
                    <PrimaryNumericField
                      {...field}
                      label={audienceType === SessionAudienceTypes.subscription ? 'Non-subscribed observer discounts (%)' : 'Observer discounts (%)'}
                      min={0}
                      max={99}
                    />
                  )}
                />
                <ErrorMessage>{errors?.observerDiscount?.message}</ErrorMessage>
              </div>
            </Grid>
          </Grid>
        </>

      ) : ''}

      <Grid container spacing={3}>
        <Grid item xs>
          <PrimaryLoadingButton loading={loading} type="submit">
            Save &
            {' '}
            {editMode ? 'Update session' : `create ${sessionType}`}
          </PrimaryLoadingButton>
        </Grid>

      </Grid>
    </form>
  );
};

export default SessionFeeForm;
