/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-destructuring */
import {
  Avatar,
  Box, CircularProgress, Divider, Grid, Typography,
} from '@mui/material';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import DOMPurify, { sanitize } from 'isomorphic-dompurify';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { editEntAccActions } from 'pages/dashboard/components/ent-account/components/edit-account/slice';
import { selectGeneralInformation } from 'pages/dashboard/components/ent-account/components/edit-account/selector';
import {
  ContentMediaView,
  Display3, PrimaryButton,
  PrimaryOutlinedButton,
} from 'components';
import { selectCreateArticle, selectLoader } from '../../../selector';
import { createArticleActions } from '../../../slice';
import Expert from './components/expert';
import Session from './components/session';

import './index.scss';

const PreviewArticle = () => {
  const { id, articleId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { article } = useSelector(selectCreateArticle);
  const articleLoader = useSelector(selectLoader);
  const entName = useSelector(selectGeneralInformation).name;
  const {
    author, highlightMedia, products, experts, contents,
  } = article;

  const activeProducts = React.useMemo(
    () => products.filter((session) => session.isActive === true && session.deleted === false),
    [products],
  );

  const activeContents = React.useMemo(
    () => contents.filter((content) => content.isActive === true && content.deleted === false),
    [contents],
  );

  // get the session , media and the experts
  React.useEffect(() => {
    if (articleId === undefined) {
      dispatch(editEntAccActions.setId(id as string));
      dispatch(editEntAccActions.getEntAcc());
    }
  }, [article]);

  // set the values when its in the article view mode
  React.useEffect(() => {
    if (articleId !== undefined) {
      dispatch(createArticleActions.getArticle(articleId));
    }
  }, [articleId]);

  React.useEffect(() => {
    DOMPurify.addHook('afterSanitizeAttributes', (node) => {
      // set all elements owning target to target=_blank
      if ('target' in node) {
        node.setAttribute('target', '_blank');
        node.setAttribute('rel', 'noopener');
      }
    });
  }, []);

  const processedArticleBody = React.useMemo(() => {
    if (!article.body) return '';
    const cleanedBody = sanitize(article.body, {
      USE_PROFILES: { html: true },
      ADD_ATTR: ['target'],
    });
    const doc = new DOMParser().parseFromString(cleanedBody, 'text/html');

    const images = doc.querySelectorAll('img');
    images.forEach((image) => {
      const imgStyle = image.style;
      imgStyle.maxWidth = '100%';
      imgStyle.height = 'auto';
    });

    return doc.body.innerHTML;
  }, [article.body]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [article]);

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div>
      {articleLoader ? <Grid container justifyContent="center" alignItems="center" sx={{ margin: 'auto', height: '80vh' }}><CircularProgress /></Grid>
        : (
          <Box sx={{ m: '2rem 0', wordWrap: 'break-word' }}>
            <div style={{ marginLeft: '12rem' }}>
              <PrimaryOutlinedButton
                startIcon={<ArrowCircleLeftOutlinedIcon />}
                onClick={handleBack}
              >
                Back to articles
              </PrimaryOutlinedButton>
            </div>
            <div style={{ margin: '1rem 15rem 3rem' }}>
              <div>
                <Display3>{article?.title}</Display3>
              </div>
              <div className="inline-wrapper">
                <Typography variant="body1" className="inline-item">
                  {author?.firstName}
                  {' '}
                  {author?.lastName}
                </Typography>
                <Typography variant="body1" className="inline-item" color="primary.main">
                  {entName}
                </Typography>
              </div>
              <Typography
                variant="h5"
                sx={{ color: 'primary.main', fontWeight: '700', mb: '2rem' }}
              >
                {article?.highlight}
              </Typography>
              <Box sx={{ height: '25rem' }}>
                <ContentMediaView
                  mediaType={highlightMedia?.fileCategory}
                  url={highlightMedia?.contentUrl}
                  thumbnail={highlightMedia?.thumbnail}
                  backgroundSize="contain"
                />
              </Box>

              <div style={{ margin: '2rem 0' }} dangerouslySetInnerHTML={{ __html: article?.lead && DOMPurify.sanitize(article?.lead) }} />
              <div style={{ margin: '2rem 0' }} dangerouslySetInnerHTML={{ __html: processedArticleBody }} />

              <Divider sx={{ m: '1rem 0' }} />
              <Grid container justifyContent="space-between" sx={{ mb: '0.5rem' }}>
                <Grid item>
                  <Grid container alignItems="center">
                    <Grid item sx={{ mr: '0.7rem' }}>
                      <Avatar src={author?.profileImgUrl} sx={{ width: 50, height: 50 }} />
                    </Grid>
                    <Grid item>
                      <Grid item>
                        <Typography variant="h4">
                          {author?.firstName}
                          {' '}
                          {author?.lastName}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">
                          {entName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>
                <Grid item>
                  <PrimaryButton>Follow</PrimaryButton>
                </Grid>
              </Grid>
              <div style={{ margin: '2rem 0' }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(author?.attributes?.bio) || '' }} />

            </div>
            {activeProducts.length > 0 && (
              <Box sx={{ backgroundColor: '#FAF9F4', border: '1px solid #EBEBEB' }}>
                <div className="article-preview-item">
                  <div style={{ textAlign: 'center' }}>
                    <Display3>Related sessions</Display3>
                  </div>
                  <Grid container sx={{ margin: '1rem 0' }}>
                    {activeProducts.map((session) => (
                      <Grid item sx={{ width: '100%' }} key={session.id}>
                        <Session session={session} author={author} entName={entName} />
                      </Grid>
                    ))}
                  </Grid>

                </div>
              </Box>
            )}

            {experts.length > 0 && (
              <Box>
                <div className="article-preview-item">
                  <div style={{ textAlign: 'center' }}>
                    <Display3>Related experts</Display3>
                  </div>
                  <Grid container sx={{ margin: '1rem 0' }} spacing={3}>
                    {experts.map((expert) => (
                      <Grid item xs={6} key={expert.id}>
                        <Expert expert={expert} entName={entName} />
                      </Grid>
                    ))}

                  </Grid>
                </div>
              </Box>
            )}

            {activeContents.length > 0 && (
              <Box>
                <div className="article-preview-item">
                  <div style={{ textAlign: 'center' }}>
                    <Display3>Related content only sessions</Display3>
                  </div>
                  <Grid container sx={{ margin: '1rem 0' }}>
                    {activeContents.map((mediaItem) => (
                      <Grid item sx={{ width: '100%' }} key={mediaItem.id}>
                        <Session session={mediaItem} author={author} entName={entName} />
                      </Grid>
                    ))}

                  </Grid>
                </div>
              </Box>
            )}
          </Box>
        )}
    </div>
  );
};

export default PreviewArticle;
