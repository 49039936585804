/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Link as NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardLayout } from 'components/templates';
import {
  PrimaryButton, StatusTag, SearchBar, PrimarySelect,
} from 'components';
import EntAccountTable from './components/ent-table';
import {
  selectEntAccounts, selectTotalActive, selectTotalInactive, selectSCAdmins,
  selectEntAccountsLoader, selectSCAdminsLoader,
} from './selector';
import { viewEntAccActions } from './slice';

const EntAccount = () => {
  const totalActive = useSelector(selectTotalActive).toString();
  const totalInactive = useSelector(selectTotalInactive).toString();
  const filteredEntAccounts = useSelector(selectEntAccounts).result;
  const { total } = useSelector(selectEntAccounts);
  const scAdmins = useSelector(selectSCAdmins);
  const entAccountLoading = useSelector(selectEntAccountsLoader);
  const scAdminsLoading = useSelector(selectSCAdminsLoader);

  const [keyword, setKeyword] = React.useState<string>('');
  const [page, setPage] = React.useState<number>(0);
  const [selectedStatus, setSelectedStatus] = React.useState<string>('');
  const [selectedSCAdmin, setSelectedSCAdmin] = React.useState<string>('');
  const [scAdminOptions, setAdminOptions] = React.useState([{ id: '1', value: 'All', text: 'All' }]);

  const handleSearchChange = (event: any) => {
    setKeyword(event.target.value);
  };
  const handleStatusChange = (event: any) => {
    setSelectedStatus(event.target.value);
  };
  const handleSCAdminChange = (event: any) => {
    setSelectedSCAdmin(event.target.value);
  };

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(viewEntAccActions.viewEntAcc({
      keyword, page: (page + 1), limit: 25, status: selectedStatus, scAdmin: selectedSCAdmin,
    }));
  }, [keyword, selectedStatus, selectedSCAdmin, page]);

  React.useEffect(() => {
    dispatch(viewEntAccActions.getSCAdmins());
  }, []);

  React.useEffect(() => {
    setAdminOptions([{ id: '1', value: 'All', text: 'All' }, ...scAdmins.map((scAdmin) => ({
      id: scAdmin.id, value: scAdmin.id, text: `${scAdmin.firstName} ${scAdmin.lastName}`,
    }))]);
  }, [scAdmins]);

  const breadcrumbs = [
    {
      name: 'Home',
      href: '/',
      render: true,
    },
    {
      name: 'Enterprise accounts',
      href: '/ent-account',
      render: true,
      isActive: true,
    },
  ];
  const statuses = [
    { id: 1, value: 'active', text: 'Active' },
    { id: 2, value: 'inactive', text: 'Inactive' },
    { id: 3, value: 'All', text: 'All' },
  ];
  const topContent = (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item>
        <Grid container alignItems="center">
          <Grid item>
            <h1>Enterprise accounts</h1>
          </Grid>
          <Grid item sx={{ marginLeft: '1rem' }}>
            <StatusTag success status={`${totalActive} Active`} />
          </Grid>
          <Grid item sx={{ marginLeft: '1rem' }}>
            <StatusTag success={false} status={`${totalInactive} Inactive`} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={3} xl={3}>
        <NavLink style={{ textDecoration: 'none' }} to="/ent-account/create-ent-account">
          <PrimaryButton size="large" fullWidth>
            <AddIcon sx={{ marginRight: '5px' }} />
            Create new account
          </PrimaryButton>
        </NavLink>
      </Grid>
    </Grid>
  );
  return (
    <div>
      <DashboardLayout breadcrumbs={breadcrumbs} topContent={topContent}>
        <div>
          <Grid
            container
            alignItems="flex-end"
            justifyContent="space-between"
            sx={{ margin: '1rem 0' }}
          >
            <Grid item xs={6}>
              <SearchBar value={keyword} onChange={handleSearchChange} placeholder="Search by enterprise name" />
            </Grid>
            <Grid item />
            <Grid item xs sx={{ paddingLeft: '1rem' }}>
              <PrimarySelect innerLabel="Status" options={statuses} onChange={handleStatusChange} />
            </Grid>
            <Grid item xs sx={{ paddingLeft: '1rem' }}>
              <PrimarySelect innerLabel="SC admin" options={scAdminOptions} onChange={handleSCAdminChange} loading={scAdminsLoading} />
            </Grid>
          </Grid>
          <div />
          <div>
            <EntAccountTable
              entAccounts={filteredEntAccounts}
              loading={entAccountLoading}
              total={total}
              page={page}
              setPage={setPage}
            />
          </div>
        </div>
      </DashboardLayout>
    </div>
  );
};
export default EntAccount;
