import React, { useState } from 'react';
import {
  Box,
  Breadcrumbs, Divider, IconButton, Typography, Link as BreadCrumbLink,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import { selectStripeAccountCompleted } from 'pages/dashboard/components/ent-account/components/edit-account/selector';
import { selectIsSlocoachSuperUser } from 'base/auth/selectors';
import { CloseIcon } from 'assets/icons';
import { BreadcrumbItem } from 'interfaces';
import AlertStrip from 'components/molecules/alert-strip';
import { authSliceActions } from 'base/auth/slice';

export interface Props {
  topContent: React.ReactElement;
  children: React.ReactElement;
  breadcrumbs: BreadcrumbItem[];
}
export default function EntAccountLayout({ topContent, children, breadcrumbs }: Props) {
  const stripeAccountCompleted = useSelector(selectStripeAccountCompleted);
  const isSuperAdmin = useSelector(selectIsSlocoachSuperUser);
  const [notificationBar, setNotificationBar] = useState(true);
  const { id } = useParams();
  const dispatch = useDispatch();
  let showNotificationBar: (string | null) = 'false';

  // setup local storage for notification
  if (!localStorage.getItem('stripeConnectNotificationCreated')) {
    localStorage.setItem('stripeConnectNotificationCreated', Date.now().toString());
    localStorage.setItem('showNotificationBar', 'true');
    showNotificationBar = localStorage.getItem('showNotificationBar');
  } else {
    const createdDate = localStorage.getItem('stripeConnectNotificationCreated');
    if (!localStorage.getItem('showNotificationBar')) {
      localStorage.setItem('showNotificationBar', 'true');
    }
    if (Number(createdDate as string) + 7 * 3600000 * 24 < Date.now()) { // one week
      localStorage.setItem('showNotificationBar', 'false');
      showNotificationBar = localStorage.getItem('showNotificationBar');
    }
  }
  const closeNotification = () => {
    localStorage.setItem('showNotificationBar', 'false');
    setNotificationBar(false);
  };

  showNotificationBar = localStorage.getItem('showNotificationBar');

  React.useEffect(() => {
    if (id) {
      dispatch(authSliceActions.setEntId(id));
    }
  }, []);

  return (
    <div>
      {(notificationBar && showNotificationBar === 'true' && !stripeAccountCompleted && !isSuperAdmin) && (
        <AlertStrip
          color="warning"
          icon={
            <PrivacyTipIcon sx={{ color: '#ed6c02' }} />
          }
          sx={{ borderBottom: '1px solid #e7e7f1' }}
          action={(
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={closeNotification}
            >
              <CloseIcon />
            </IconButton>
          )}
        >
          <Box sx={{ display: 'flex' }}>
            To sell products on Slocoach, connect your bank account using Stripe.
            Sign up for a Stripe account,
            then connect it to Slocoach through the

            <Link
              to={`/ent-account/edit/${id}/payments`}
              style={{
                textDecoration: 'none', display: 'flex', alignItems: 'center', marginLeft: 10, marginRight: 10,
              }}
            >
              <Typography variant="body2" sx={{ color: 'black', fontWeight: 700 }}>
                Payments
              </Typography>
            </Link>
            tab in your account settings.
          </Box>
        </AlertStrip>

      )}
      <div style={{ padding: '1rem 3rem' }}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          {breadcrumbs.map(
            (breadcrumb) => breadcrumb.render && (
            <BreadCrumbLink
              component={Link}
              key={breadcrumb.name}
              underline="hover"
              color={breadcrumb?.isActive ? 'text.primary' : 'text.secondary'}
              fontWeight="bold"
              to={breadcrumb?.isActive ? '#' : breadcrumb.href}
              aria-current={breadcrumb?.isActive ? 'page' : undefined}
              data-testid={`breadcrumb-${breadcrumb.name.replace(/\s+/g, '-').toLowerCase()}`}
            >
              {breadcrumb.name}
            </BreadCrumbLink>
            ),
          )}
        </Breadcrumbs>
        <div style={{ padding: '1rem 0' }}>{topContent}</div>

        <Divider style={{ width: '100%' }} />
        {children}
      </div>
    </div>
  );
}
