import * as React from 'react';
import moment from 'moment';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { SessionAudienceTypes, SessionType } from 'config';
import SessionAvailabilityForm from '../availability-form';
import sessionAvailabilityFormValidation from '../../validation/availability-form-vlaidation';
import {
  selectCourseOnlySessionMeta,
  selectLiveAvailability, selectSessionFee, selectSessionInformation,
} from '../../../../selector';
import { CourseOnlySessionStatus, ILiveSessionAvailabilityForm } from '../../../../interface';
import { createSessionActions } from '../../../../slice';

interface SessionDetailsViewProps {
  editMode: boolean
}

export default function SessionAvailabilityView({ editMode }: SessionDetailsViewProps) {
  const dispatch = useDispatch();
  const availableTimes = useSelector(selectLiveAvailability);
  const { sessionType, audienceType } = useSelector(selectSessionInformation);
  const { status } = useSelector(selectCourseOnlySessionMeta);
  const sessionFee = useSelector(selectSessionFee);

  const methods = useForm<ILiveSessionAvailabilityForm>({
    defaultValues: {
      availableTimes: availableTimes.availableTimes,
      sessionMeta: availableTimes.sessionMeta,
    },
    resolver: yupResolver(sessionAvailabilityFormValidation),
    shouldFocusError: true,
  });

  const onSubmit:
  SubmitHandler<ILiveSessionAvailabilityForm> = React.useCallback((data) => {
    if (sessionType === SessionType.liveSession) {
      // checking available times are in correct order
      const sessionAvailableTimes = data.availableTimes.filter((av) => av.isAvailable);
      const isAvailableTimesInCorrectOrder = sessionAvailableTimes.every((sessionAvailableTime) => {
        const times = sessionAvailableTime.times.filter((time) => time.from !== '' && time.to !== '');
        return times.every((time, index) => {
          if (index === 0) return true;
          return moment(time.from, 'HH:mm:ss').isSameOrAfter(moment(times[index - 1].to, 'HH:mm:ss'));
        });
      });

      if (!isAvailableTimesInCorrectOrder) {
        methods.setError('availableTimes', { type: 'custom', message: 'The selected start time must be later than the end time of the previous time slot.' });
        return false;
      }
      methods.clearErrors('availableTimes');
    }

    dispatch(createSessionActions.AddLiveSessionAvailability(data));
    if (audienceType === SessionAudienceTypes.courseOnly) {
      if (status === CourseOnlySessionStatus.editing || editMode) {
        dispatch(createSessionActions.editSession(sessionFee));
      } else {
        dispatch(createSessionActions.createSession(sessionFee));
      }
    }

    return true;
  }, [dispatch]);

  return (
    <div>
      <FormProvider {...methods}>
        <SessionAvailabilityForm
          onSubmit={onSubmit}
        />
      </FormProvider>
    </div>
  );
}
