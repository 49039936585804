/* eslint-disable react/require-default-props */
import React from 'react';
import { FormControl, InputLabel, CircularProgress } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { IOption } from 'interfaces';
import './index.scss';
import { Label } from '../custom-typography';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      border: '1px solid #E5E5E5',
      marginTop: '5px',
    },
  },
};

interface PrimarySelectProps<T = unknown> {
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onChange?: any;
  value?: string;
  name?: string;
  label?: string;
  innerLabel?: string;
  options: IOption[];
  placeholder?: string;
  loading?: boolean;
  renderValue?: string;
  disabled?: boolean;
}

const PrimarySelect = React.forwardRef(
  (
    {
      onChange,
      onBlur,
      value,
      name,
      label,
      innerLabel,
      options,
      placeholder,
      loading,
      renderValue,
      disabled,
    }: PrimarySelectProps,
    ref,
  ) => (
    <div>
      <Label>{label}</Label>

      <div className="primary-select-wrapper">
        {loading ? (
          <div style={{ margin: 'auto' }}>
            <CircularProgress size={20} />
          </div>
        ) : (
          <FormControl fullWidth size="small">
            <InputLabel id="select-small-lb">{innerLabel}</InputLabel>
            <Select
              labelId="select-small-lb"
              id="select-small"
              value={value}
              label={innerLabel}
              onChange={onChange}
              onBlur={onBlur}
              disabled={disabled}
              fullWidth
              placeholder={placeholder ? undefined : (options[0].value as string)}
              defaultValue={placeholder ? undefined : (options[0].value as string)}
              MenuProps={MenuProps}
              name={name}
            // input={<BootstrapInput />}
              displayEmpty
              ref={ref}
              renderValue={renderValue ? () => renderValue : undefined}
            >
              <MenuItem disabled value="">
                <div style={{ color: '#B2B2B2' }}>{placeholder}</div>
              </MenuItem>
              {options.map((option: any) => (
                <MenuItem
                  key={option.id}
                  disabled={option.disabled}
                  value={option.value}
                  style={{ color: option.color }}
                >
                  {option.icon && <span style={{ marginRight: '5px', display: 'flex' }}>{option.icon}</span>}
                  {option.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </div>
    </div>
  ),
);
export default PrimarySelect;
