import React from 'react';
import { Breadcrumbs, Divider, Link as BreadCrumbLink } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { PrimarySnackbar } from 'components/molecules';
import { selectSnack } from 'base/core/selector';
import { coreSliceActions } from 'base/core/slice';
import { BreadcrumbItem } from 'interfaces';
import TopBar from '../organisms/top-bar';

export interface Props {
  topContent: React.ReactElement;
  children: React.ReactElement;
  breadcrumbs: BreadcrumbItem[];
}

export default function DashboardLayout({ topContent, children, breadcrumbs }: Props) {
  const dispatch = useDispatch();
  const { open, message, success } = useSelector(selectSnack);
  const handleClose = () => {
    dispatch(coreSliceActions.closeSnackbar());
  };
  return (
    <div>
      <TopBar />

      <div style={{ padding: '2rem' }}>
        <Breadcrumbs style={{ letterSpacing: '1px', fontSize: '14px' }} separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          {breadcrumbs.map(
            (breadcrumb) => breadcrumb.render && (
            <BreadCrumbLink
              component={Link}
              key={breadcrumb.name}
              underline="hover"
              color={breadcrumb?.isActive ? 'text.primary' : 'text.secondary'}
              fontWeight="bold"
              to={breadcrumb?.isActive ? '#' : breadcrumb.href}
              aria-current={breadcrumb?.isActive ? 'page' : undefined}
              data-testid={`breadcrumb-${breadcrumb.name.replace(/\s+/g, '-').toLowerCase()}`}
            >
              {breadcrumb.name}
            </BreadCrumbLink>
            ),
          )}
        </Breadcrumbs>
        <div style={{ padding: '1rem 0' }}>{topContent}</div>
        <Divider style={{ width: '100%' }} />
        <PrimarySnackbar open={open} message={message} severity={success ? 'success' : 'error'} handleClose={handleClose} />
        {children}
      </div>
    </div>
  );
}
