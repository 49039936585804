import { Controller, SubmitHandler, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  PrimaryTextField,
  ErrorMessage,
  BlackLabel,
  PrimaryLink,
  PrimaryLabel,
  PrimaryLoadingButton,
} from 'components';
import { IResetTempPasswordForm } from 'pages/reset-temp-password/interface';
import { selectLoader } from 'base/auth/selectors';

interface ResetTempPasswordFormProps {
  onSubmit: SubmitHandler<IResetTempPasswordForm>;
}

const ResetTempPasswordForm = ({ onSubmit }: ResetTempPasswordFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormContext<IResetTempPasswordForm>();
  const loading = useSelector(selectLoader);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Controller control={control} name="password" render={() => <div />} />
        <ErrorMessage>{errors?.password?.message}</ErrorMessage>
      </div>
      <div>
        <Controller control={control} name="name" render={() => <div />} />
        <ErrorMessage>{errors?.name?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="newPassword"
          render={({ field }) => (
            <PrimaryTextField {...field} label="New Password" testId="reset-temp-password" isPassword />
          )}
        />
        <ErrorMessage>{errors?.newPassword?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="confirmPassword"
          render={({ field }) => (
            <PrimaryTextField {...field} label="Confirm password" isPassword />
          )}
        />
        <ErrorMessage>{errors?.confirmPassword?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <PrimaryLoadingButton loading={loading} size="large" fullWidth type="submit">
          Set password and get started
        </PrimaryLoadingButton>
      </div>
      <div style={{ textAlign: 'center', marginBottom: '10px' }}>
        <BlackLabel>Have an account? </BlackLabel>
        <PrimaryLink to="/login">
          <PrimaryLabel>
            <span style={{ cursor: 'pointer' }}>Sign in</span>
          </PrimaryLabel>
        </PrimaryLink>
      </div>
      <div style={{ textAlign: 'center' }}>
        <BlackLabel>Need help? </BlackLabel>
        <a href="mailto:help@slocoach.com" style={{ color: 'inherit', textDecoration: 'none' }}>
          <PrimaryLabel>
            <span style={{ cursor: 'pointer' }}>Contact us </span>
          </PrimaryLabel>
        </a>
      </div>
    </form>
  );
};

export default ResetTempPasswordForm;
