/* eslint-disable consistent-return */
import {
  FormGroup, FormControlLabel, Grid, Box, Tab, Tabs, CircularProgress,
} from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { selectGeneralInformation as selectGeneralEntInformation } from 'pages/dashboard/components/ent-account/components/edit-account/selector';
import { selectGeneralInformation, selectActive as selectIsExpertActive } from 'pages/dashboard/components/ent-account/components/edit-account/components/experts/components/edit-expert/selector';
import { a11yProps } from 'components/molecules/tab-panel';
import { EntAccountLayout } from 'components/templates';
import {
  PrimarySwitch, PrimaryLiteButton, TabPanel, ClosableModal,
} from 'components';
import { IMessageEvent, MessageType } from 'interfaces';
import { selectWindowIsDisabled } from 'base/core/selector';
import { selectIsSlocoachSuperUser } from 'base/auth/selectors';
import { editEntAccActions } from 'pages/dashboard/components/ent-account/components/edit-account/slice';
import { SessionType, CourseTypes } from 'config';
import { viewPluginActions } from 'pages/dashboard/components/ent-account/components/edit-account/components/plugins/components/view-plugins/slice';
import { selectEntId } from 'pages/dashboard/components/ent-account/components/edit-account/components/plugins/components/view-plugins/selector';
import { createSessionActions } from './slice';
import {
  selectActive, selectStateLoader, selectSessionInformation, selectStatus,
  selectCourseOnlySessionMeta,
} from './selector';
import SessionFeeView from './components/session-fees';
import SessionInformationView from './components/session-information/components/session-information-view';
import SuccessfulView from './components/successful-view';
import SessionAvailabilityView from './components/availability';
import CourseModulesView from './components/modules';
import { viewContentActions } from '../../../content/slice';
import { viewSessionsActions } from '../view-sessions/slice';
import SessionFrequencyView from './components/frequency';
import { editExpertActions } from '../../../../slice';
import CourseGoalsView from './components/goals';
import SessionDetailsView from './components/session-details';
import { CourseOnlySessionStatus } from './interface';

interface CreateSessionProps {
  editMode?: boolean
  courseOnlySession?: boolean
}

const CreateSession = ({ editMode = false, courseOnlySession = false }: CreateSessionProps) => {
  const dispatch = useDispatch();
  const {
    expertId, id, sessionId,
  } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isEditMode, setIsEditMode] = React.useState(editMode);
  const navSessionType = useParams().sessionType;
  const isSuperAdmin = useSelector(selectIsSlocoachSuperUser);
  const navigate = useNavigate();
  const generalEntInformation = useSelector(selectGeneralEntInformation);
  const generalExpertInformation = useSelector(selectGeneralInformation);
  const stateLoading = useSelector(selectStateLoader);
  const status = useSelector(selectStatus);
  const active = useSelector(selectActive);
  const isExpertActive = useSelector(selectIsExpertActive);
  const { sessionType, courseType, audienceType } = useSelector(selectSessionInformation);
  const courseOnlySessionStatus = useSelector(selectCourseOnlySessionMeta).status;
  const windowIsDisabled = useSelector(selectWindowIsDisabled);
  const entId = useSelector(selectEntId);
  const [open, setOpen] = React.useState(false);
  const updateActive = () => {
    if (isExpertActive || active) {
      dispatch(createSessionActions.updateActive(!active));
    } else {
      setOpen(true);
    }
  };
  const cancel = () => {
    dispatch(createSessionActions.reset());
    navigate(`/ent-account/edit/${id}/experts/edit-expert/${expertId}/sessions`);
  };

  useEffect(() => {
    if (entId) {
      dispatch(viewPluginActions.viewPlugins());
    }
  }, []);

  useEffect(() => {
    dispatch(viewPluginActions.setEntId(id as string));
  }, [id]);

  const breadcrumbs = [
    {
      name: 'Home',
      href: '/',
      render: true,
    },
    {
      name: 'Enterprise accounts',
      href: '/ent-account',
      render: isSuperAdmin,
    },
    {
      name: generalEntInformation.name,
      href: `/ent-account/edit/${id}/general-information`,
      render: true,
    },
    {
      name: `${generalExpertInformation.firstName} ${generalExpertInformation?.lastName}`,
      href: `/ent-account/edit/${id}/experts/edit-expert/${expertId}`,
      render: true,
    },
    {
      name: `${editMode ? 'Edit session' : 'Create new session'}`,
      href: `${editMode ? `/ent-account/edit/${id}/experts/edit-expert/${expertId}/sessions/edit-session/${sessionId}` : `/ent-account/edit/${id}/experts/edit-expert/${expertId}/sessions/create/${sessionType.toLocaleLowerCase().replace(' ', '-')}`}`,
      render: true,
      isActive: true,
    },
  ];

  const topContent = (
    <Grid container alignItems="center">
      <Grid item xs={10}>
        <Grid container alignItems="center">
          <Grid item>
            <h2>
              {courseOnlySession ? courseOnlySessionStatus !== CourseOnlySessionStatus.editing ? 'Create new course only' : 'Edit Course only' : editMode ? 'Edit' : 'Create new'}
              {' '}
              {sessionType}
              {' '}
              {courseOnlySession && 'for your course'}
            </h2>
          </Grid>
          {!courseOnlySession
            && (
              <Grid item sx={{ marginLeft: '3rem' }}>
                <FormGroup>
                  <FormControlLabel
                    control={<PrimarySwitch checked={active} onChange={updateActive} />}
                    label={active ? 'Active' : 'Inactive'}
                  />
                </FormGroup>
              </Grid>
            )}
        </Grid>
      </Grid>
      { !courseOnlySession && (
      <Grid item xs sx={{ textAlign: 'right' }}>
        <PrimaryLiteButton size="small" onClick={cancel}>Cancel</PrimaryLiteButton>
      </Grid>
      )}

    </Grid>
  );

  useEffect(() => {
    dispatch(createSessionActions.reset());
  }, []);

  React.useEffect(() => {
    if (courseOnlySession) {
      const handleBeforeUnload = (event: any) => {
        event.preventDefault();
        const message:IMessageEvent = { type: MessageType.windowClose, data: '' };
        window.opener.postMessage(message, window.origin);
        return true;
      };

      const windowOpenMessage = { type: MessageType.windowOpen, data: '' };
      window.opener.postMessage(windowOpenMessage, window.origin);

      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, []);

  React.useEffect(() => {
    dispatch(createSessionActions.reset());
    dispatch(createSessionActions.setExpertId(expertId as string));
    dispatch(createSessionActions.setEntId(id as string));
    dispatch(viewSessionsActions.setExpertId(expertId as string));
    dispatch(viewContentActions.setExpertId(expertId as string));
    dispatch(editExpertActions.setId(expertId as string));
    dispatch(editExpertActions.getExpert());
    if (!courseOnlySession) {
      if (editMode) {
        dispatch(createSessionActions.setSessionId(sessionId as string));
        dispatch(createSessionActions.getSession());
      } else {
        const type = (navSessionType as string).replace('-', ' ').split(' ');
        if (type) {
          type[0] = type[0].charAt(0).toUpperCase() + type[0].slice(1);
          if (type[0] === 'Live') {
            type[0] = 'LIVE';
          }
        }
        dispatch(createSessionActions.reset());
        dispatch(createSessionActions.setSessionType(type.join(' ')));
      }
    }
    if (courseOnlySession) {
      const moduleIndex = searchParams.get('moduleIndex');
      dispatch(createSessionActions.setCourseOnlySessionMetaModule(
        parseInt(moduleIndex || '0', 10) as number,
      ));
      if (id) {
        dispatch(editEntAccActions.setId(id as string));
        dispatch(editEntAccActions.getEntAcc());
      }
    }
  }, []);

  // set the session to be active initially , if the expert is active
  React.useEffect(() => {
    if (!editMode) {
      dispatch(createSessionActions.updateActive(isExpertActive));
    }
  }, [editMode, isExpertActive, sessionType]);

  React.useEffect(() => {
    if (courseOnlySession) {
      if (courseOnlySessionStatus === CourseOnlySessionStatus.editing) {
        setIsEditMode(true);
      } else {
        setIsEditMode(false);
      }
    }
  }, [courseOnlySessionStatus]);

  return (
    <div>
      {stateLoading ? (
        <Grid container justifyContent="center" alignItems="center" sx={{ margin: 'auto', height: '80vh' }}>
          <CircularProgress />
        </Grid>
      ) : (
        <EntAccountLayout
          breadcrumbs={!courseOnlySession ? breadcrumbs : []}
          topContent={topContent}
        >
          <Grid container justifyContent="start-flex" sx={{ width: '100%', marginTop: '1rem' }}>
            <Grid item xs={10}>

              <Box>

                <Tabs value={Math.min(status, 4)}>

                  <Tab
                    className={(status >= 1) ? 'tab completed' : 'tab'}
                    label="1. Session information"
                    {...a11yProps(0)}
                    disabled={
                      (sessionType === SessionType.course && courseType === CourseTypes.interactive)
                        ? status > 4
                        : status >= 4
                    }
                    icon={(status >= 1) ? <CheckCircleIcon /> : ''}
                    iconPosition="start"
                    onClick={() => dispatch(createSessionActions.setStatus(0))}
                  />
                  <Tab
                    className={(status >= 1) ? 'tab completed' : 'tab'}
                    label="2. Session details"
                    {...a11yProps(1)}
                    disabled={
                      (sessionType === SessionType.course && courseType === CourseTypes.interactive)
                        ? (status < 1) || (status > 4)
                        : (status < 1) || (status >= 4)
                    }
                    icon={(status >= 2) ? <CheckCircleIcon /> : ''}
                    iconPosition="start"
                    onClick={() => dispatch(createSessionActions.setStatus(1))}
                  />
                  {(sessionType === SessionType.course
                    && courseType === CourseTypes.interactive) ? (
                      <Tab
                        className={(status >= 2) ? 'tab completed' : 'tab'}
                        label="3. Goals "
                        {...a11yProps(2)}
                        disabled={(status < 2) || (status > 4)}
                        icon={(status >= 3) ? <CheckCircleIcon /> : ''}
                        iconPosition="start"
                        onClick={() => dispatch(createSessionActions.setStatus(2))}
                      />

                    ) : ''}
                  {sessionType === SessionType.course ? (
                    <Tab
                      className={((status >= 3 && courseType === CourseTypes.lsm) || (status >= 4 && courseType === CourseTypes.interactive)) ? 'tab completed' : 'tab'}
                      label={courseType === CourseTypes.lsm ? '3. Modules' : '4. Modules'}
                      {...a11yProps(2)}
                      disabled={
                        courseType === CourseTypes.interactive
                          ? (status < 3 || status > 4)
                          : (
                            courseType === CourseTypes.lsm
                              ? (status < 2 || status >= 4)
                              : (status < 3 || status >= 4)
                          )
                      }
                      icon={((status >= 3 && courseType === CourseTypes.lsm) || (status >= 4 && courseType === CourseTypes.interactive)) ? <CheckCircleIcon /> : ''}
                      iconPosition="start"
                      onClick={() => {
                        const value = courseType === CourseTypes.lsm ? 2 : 3;
                        dispatch(createSessionActions.setStatus(value));
                      }}
                    />

                  ) : ''}
                  {sessionType === SessionType.liveClass ? (
                    <Tab
                      className={(status >= 3) ? 'tab completed' : 'tab'}
                      label="3. Frequency "
                      {...a11yProps(2)}
                      disabled={(status < 2) || (status >= 4)}
                      icon={(status >= 3) ? <CheckCircleIcon /> : ''}
                      iconPosition="start"
                      onClick={() => dispatch(createSessionActions.setStatus(2))}
                    />

                  ) : ''}
                  {sessionType === SessionType.liveSession ? (
                    <Tab
                      className={(status >= 3) ? 'tab completed' : 'tab'}
                      label="3. Availability details "
                      {...a11yProps(2)}
                      disabled={(status < 2) || (status >= 4)}
                      icon={(status >= 3) ? <CheckCircleIcon /> : ''}
                      iconPosition="start"
                      onClick={() => dispatch(createSessionActions.setStatus(2))}
                    />

                  ) : ''}
                  {!courseOnlySession && (
                    (sessionType === SessionType.content && audienceType !== 'course only')
                      || sessionType === SessionType.digitalSession ? (
                        <Tab
                          className={(status >= 3) ? 'tab completed' : 'tab'}
                          label="3. Session Fees "
                          {...a11yProps(2)}
                          disabled={(status < 2) || (status >= 4)}
                          icon={(status >= 3) ? <CheckCircleIcon /> : ''}
                          iconPosition="start"
                          onClick={() => dispatch(createSessionActions.setStatus(2))}
                        />
                      ) : (audienceType !== 'course only'
                        && (
                          <Tab
                            className={((status >= 4 && courseType === CourseTypes.lsm) || (status >= 5 && courseType === CourseTypes.interactive)) ? 'tab completed' : 'tab'}
                            label={courseType === CourseTypes.lsm ? '4. Session Fees' : '5. Session Fees'}
                            {...a11yProps(3)}
                            disabled={courseType === CourseTypes.lsm
                              ? ((status < 3) || (status >= 4))
                              : ((status < 4) || (status >= 5))}
                            icon={((status >= 4 && courseType === CourseTypes.lsm) || (status >= 5 && courseType === CourseTypes.interactive)) ? <CheckCircleIcon /> : ''}
                            iconPosition="start"
                            onClick={() => {
                              const value = courseType === CourseTypes.lsm ? 3 : 2;
                              dispatch(createSessionActions.setStatus(value));
                            }}
                          />
                        )
                      )
                  )}

                </Tabs>
              </Box>
              <TabPanel value={status} index={0}>
                <SessionInformationView
                  editMode={isEditMode}
                  courseOnlySession={courseOnlySession}
                />
              </TabPanel>
              <TabPanel value={status} index={1}>
                <SessionDetailsView editMode={isEditMode} />
              </TabPanel>
              {(sessionType === SessionType.course && courseType === CourseTypes.lsm) ? (
                <TabPanel value={status} index={2}>
                  <CourseModulesView />
                </TabPanel>
              ) : ''}
              {(sessionType === SessionType.course && courseType === CourseTypes.interactive) ? (
                <TabPanel value={status} index={2}>
                  <CourseGoalsView />
                </TabPanel>
              ) : ''}

              {(sessionType === SessionType.course && courseType === CourseTypes.interactive) ? (
                <TabPanel value={status} index={3}>
                  <CourseModulesView />
                </TabPanel>
              ) : ''}
              {sessionType === SessionType.liveClass ? (
                <TabPanel value={status} index={2}>
                  <SessionFrequencyView editMode={isEditMode} />
                </TabPanel>
              ) : ''}
              {sessionType === SessionType.liveSession ? (
                <TabPanel value={status} index={2}>
                  <SessionAvailabilityView editMode={isEditMode} />
                </TabPanel>
              ) : ''}
              {!courseOnlySession && (
                sessionType === SessionType.content
                  || sessionType === SessionType.digitalSession ? (
                    <TabPanel value={status} index={2}>
                      <SessionFeeView editMode={isEditMode} />
                    </TabPanel>
                  ) : (
                    <TabPanel value={status} index={courseType === CourseTypes.lsm ? 3 : 4}>
                      <SessionFeeView editMode={isEditMode} />
                    </TabPanel>
                  )
              )}

              {sessionType === SessionType.content || sessionType === SessionType.digitalSession ? (
                <TabPanel value={Math.min(status, 3)} index={3}>
                  <SuccessfulView editMode={isEditMode} />
                </TabPanel>
              ) : (
                <TabPanel
                  value={Math.min(status, (courseType === CourseTypes.lsm ? 4 : 5))}
                  index={courseType === CourseTypes.lsm ? 4 : 5}
                >
                  <SuccessfulView editMode={isEditMode} />
                </TabPanel>
              )}

            </Grid>
            <ClosableModal
              open={open}
              onClose={() => setOpen(false)}
              title="Please activate expert to activate the session"
              subTitle=""
              modalClass="expert-inactive"
            >
              <div />
            </ClosableModal>
            {(windowIsDisabled && !courseOnlySession
            && courseOnlySessionStatus !== CourseOnlySessionStatus.done) && (
            <div className="overlay">
              <div className="overlay-content">
                <p>Please wait while the session is being added...</p>
              </div>
            </div>
            )}
          </Grid>

        </EntAccountLayout>
      )}
    </div>

  );
};

CreateSession.defaultProps = {
  editMode: false,
  courseOnlySession: false,
};
export default CreateSession;
